@import '../../assets/scss/utils/variables.scss';
.teams{
   padding-top:350px;
   box-sizing: border-box;
   height: 100%;
   .text{
      position: absolute;
      width: 100%;
      text-align: center;
      top: 200px;
      background-color: transparent;
    }
   .tabs-container{
      width:100%;
      display: flex;
      justify-content: center;
      align-items:flex-start;
      box-sizing: border-box;
   }
}

@media (max-width:$breakpoint-md) {
   .teams{
      padding-top: 200px;
      &>div{
        &:last-child{
           &>div{
            padding: 56px 24px;
            position: relative;
            h1{
               padding-bottom: 0px;
            }
           }
        }
     }
     &+footer{
      background-color: $color-white;
     }
   }
   .text{
     top:130px !important;
     &>div{
       display: none;
     }
     h1{
       color: $color-ebony-clay;
       text-align: center;
     }
   }
 }