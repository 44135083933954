@import '../../assets/scss/utils/variables.scss';
.container {
   padding-top: 200px;

   .content{
     width: 100%;
     padding : clamp(2rem, 1.2099rem + 3.9506vw, 5rem) 24px clamp(4rem, 0.214rem + 18.93vw, 18.375rem) 24px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     gap:32px;
   }
 }

 @media (max-width: $breakpoint-md) {
   .container{
      padding-top: 120px;
      &>h1{
        color: $color-ebony-clay;
      }
      &>div{
        &:nth-child(2){
          display:none;
        }
        &:last-child{
          margin-top: 24px;
          padding: 56px 24px;
          h1{
            padding:0px;
          }
        }
      }
      &+footer{
        background-color: $color-white;
      }
   }
 }
