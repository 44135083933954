@import "../../../assets/scss/utils/variables";

.slides-section {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: $color-white;

  .slides {
    overflow: hidden;
    width: 100%;
    aspect-ratio: 13/5;
    height: 100%;
    position: relative;
    display: flex;
    border-top: clamp(0.875rem, 0.725rem + 0.75vw, 1.625rem) solid $color-white;
    border-bottom: clamp(0.875rem, 0.725rem + 0.75vw, 1.625rem) solid $color-white;
    border-left: clamp(0.875rem, 0.725rem + 0.75vw, 1.625rem) solid $color-white;

    .slide{
      transition: all 700ms ;
      position: absolute;
      width: 60%;
      aspect-ratio: 18/11;
      background: $color-grey;
      border-right: clamp(0.875rem, 0.725rem + 0.75vw, 1.625rem) solid $color-white;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0;
      margin: 0;

      &>div{
        display: flex;
        flex-direction: column;
        gap: clamp(3rem, 2.1375rem + 4.3125vw, 7.3125rem);
        padding-left: clamp(1.125rem, 0.45rem + 3.375vw, 4.5rem);
        box-sizing: border-box;
        position: relative;
        a{
          display: flex;
          z-index: 3;
          flex-wrap: wrap;
          font-size: clamp(1.5rem, -0.5026rem + 4.1721vw, 3.5rem);
          font-weight: 600;
          line-height: clamp(1.875rem, 1.1125rem + 3.8125vw, 5.6875rem);
          letter-spacing: 0px;
          text-align: left;
          color:$color-main-text;
          width:100%;
          max-width: clamp(7.875rem, 4.45rem + 17.125vw, 25rem);

        }
        &>div{
          display: flex;
          gap: clamp(1.25rem, 0.8625rem + 1.9375vw, 3.1875rem);
          svg{
            cursor: pointer;
            width: clamp(2.0625rem, 1.45rem + 3.0625vw, 5.125rem);
          }
        }
      }
      img{
        height: 100%;
        width: 100%;
        max-width: clamp(19.75rem, 0.0357rem + 30.8036vw, 37rem);
        border-top-left-radius: 100%;
        border-bottom-left-radius: 100%;
        width: auto;
        object-fit: cover;
        user-select:none;
        cursor: pointer;
      }

      &:first-child{
        svg{
          &:first-child{
            display : none;
            cursor: not-allowed;
            cursor: -moz-not-allowed;
            cursor: -webkit-not-allowed;
          }
        }
      }
      &:last-child{
        svg{
          &:last-child{
            display : none;
            cursor: not-allowed;
            cursor: -moz-not-allowed;
            cursor: -webkit-not-allowed;
          }
        }
      }
    }


  }
}

@media (max-width: $breakpoint-lg) {
  .slides{
     aspect-ratio: 18/11 !important;
     width: 100%;
     border-right: 14px solid $color-white !important;
    .slide{
      width: 100% !important;
      border-right: none !important;
      img{
        max-width: clamp(10.5rem, 0.0787rem + 59.5506vw, 37rem) !important;
      }
    }
  }

}