@import "../../../../assets/scss/utils/variables";


.news-card {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: clamp(15.5rem, 13.6564rem + 9.2181vw, 22.5rem);
  cursor: pointer;
  padding-bottom: 10px;

  .header {
    overflow: hidden;
    img {
      transition: all 400ms;
      object-fit: contain;
      width:100%;
      height: 256px;
      object-fit: cover;
    }
  }

  .body {
    border: 1px solid $color-light-grey;
    padding: clamp(1rem, 0.8683rem + 0.6584vw, 1.5rem);
    width: 100%;
    height: clamp(8rem, 7.8683rem + 0.6584vw, 8.5rem);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: clamp(0.875rem, 0.8092rem + 0.3292vw, 1.125rem);
    transition: all 400ms;

    strong {
      font-weight: 600;
      font-size: clamp(1rem, 0.9342rem + 0.3292vw, 1.25rem);
      line-height: clamp(1.375rem, 1.3421rem + 0.1646vw, 1.5rem);
      color: $color-main-text;
      text-align: left;
      transition: all 400ms;
    }

    .footer {
      display: flex;
      justify-content: space-between;
      align-items: center;

      a {
        display: flex;
        align-items: center;
        column-gap: 14px;      
        transition: all 400ms;
        span{
          font-size: 16px;
          line-height: 20px;
          color: $color-main-text;
          font-weight: 600;
        }
        svg {
          transition: all 400ms;
          path {
            stroke: $color-main-blue;
          }
        }

        &:hover {
          svg {
            transform: translateX(10px);
          }
        }
      }

      span {
        color: $color-secondary-text;
        font-size: 14px;
        line-height: 18px;
        transition: all 400ms;
      }
    }
  }

  &:hover {
    .header {
      img {
        transform: scale(1.2);
      }
    }

    .body {
      background: $color-main-blue;

      strong {
        color: $color-white;
      }

      .footer {
        color: $color-white;

        a {
          color: $color-white;

          svg {
            path {
              stroke: $color-white;
            }
          }
        }

        span {
          color: $color-white;
        }
      }
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .footer{
    flex-direction: row-reverse;
    a{
      span{
        display: none;
      }
    }
  }
}