@import '../../assets/scss/utils/variables.scss';
.container{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   &>p{
      display : none;
   }
   &>div{
      &:first-child{
         display: none;
      }
   }
   .text{
     display:none;
   }
}
@media (max-width: $breakpoint-md) {
   .container{
      &>p{
         display: block;
         font-style: normal;
         font-weight: 400;
         font-size: 15px;
         line-height: 28px;
         color:$color-secondary-text;
         padding:16px;
      }
      &>div{
         &:first-child{
            display: block;
         }
         &:nth-of-type(3){
            display: none;
         }
         &:nth-of-type(4){
            margin-bottom:300px;
         }
         &:last-child{
            padding: 56px 24px;
            position: relative;
            margin-top: - 238px;
            h1{
               padding-bottom: 0px;
            }
         }
      }
      .text{
         padding-top:120px;
         width: 100%;
         display: flex;
         justify-content: center;
         align-items: center;
         h1{
            color: $color-ebony-clay;
         }
      }
      &+footer{
         background-color: $color-white;
      }
   }
}