.table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: clamp(2.5rem, -1.0046rem + 7.3012vw, 6rem) 0px 170px 24px !important;
  width: 100%;
  gap: clamp(2rem, -0.0026rem + 4.1721vw, 4rem);
  background-color: #fff;
}
.table .react-tabs__tab-list {
  padding: 0px 0px 0px clamp(0rem, -11.8905rem + 24.7718vw, 11.875rem);
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.table .react-tabs__tab {
  cursor: pointer;
  width: 100%;
  max-width: 264px;
  background: #fff;
  padding: clamp(1rem, 0.4993rem + 1.043vw, 1.5rem) 24px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #12263E;
}
.table .react-tabs__tab--selected {
  box-shadow: 0px -2px 0px 0px #0082CA inset;
  color: #0082CA;
}
.table .react-tabs__tab--disabled {
  cursor: default;
}
.table .react-tabs__tab:focus {
  outline: none;
}
.table .react-tabs__tab-panel {
  display: none;
  width: 100%;
}
.table .react-tabs__tab-panel--selected {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.table .react-tabs__tab-panel > div {
  width: 100%;
  padding:0px 0px 0px clamp(1rem, -10.8333rem + 24.6528vw, 18.75rem);
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start !important;
  justify-content: flex-start;
  gap: 24px;
}
.table .react-tabs__tab-panel a {
  margin-top: 64px;
  width: 100%;
  max-width: 209px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
.table .react-tabs__tab-panel a:hover svg {
  transition: all 400ms;
  transform: translateY(8px);
}



.team-swiper {
  width: 100%;
  height: 100%;
}

.team-swiper .swiper-slide {
  width : 248px;
  height:360px;
  text-align: center;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
@media (max-width: 768px) {
  .table .react-tabs__tab-list {
    justify-content: center;
  }
}