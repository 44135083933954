@import '../../../assets/scss/utils/variables.scss';
.contact{
   width: 100%;
   height: 100%;
   padding: 120px 24px 56px 24px;
   display: none;
   justify-content: center;
   flex-direction: column;
   gap:32px;
   h1{
      color:$color-ebony-clay;
   }
   button{
      height:56px;
   }
   &>div{
       display: flex;
       gap:12px;
       align-items: center;
   }
}
@media (max-width: $breakpoint-md) {
   .contact{
      display: flex;
   }
}