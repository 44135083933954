.news-internal-slide {
  width: 100%;
  height: 100%;
  padding:16px;
}
.news-internal-slide .swiper-slide {
   width: clamp(6.5rem, -0.959rem + 42.623vw, 19.5rem) !important;
   height: clamp(4.5rem, -0.6639rem + 29.5082vw, 13.5rem);
   position: relative;
   background: #fff;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
}
.video-slide-item{
   position: relative;
   display: -webkit-box;
   display: -ms-flexbox;
   display: -webkit-flex;
   display: flex;
   -webkit-box-pack: center;
   -ms-flex-pack: center;
   -webkit-justify-content: center;
   justify-content: center;
   -webkit-box-align: center;
   -ms-flex-align: center;
   -webkit-align-items: center;
   align-items: center;
}
.video-slide-item> div{
   position: absolute;
   background-color: #0082CA;
   padding: 12px ;
   display:flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   height: 40px;
   width: 40px;
}
.video-slide-item>div>svg{
   width: 9px;
}
.news-internal-slide .swiper-slide img {
  cursor:pointer;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.video{
   position: relative;
}
.video div{
   position: absolute;
   cursor: pointer;
   border-radius:50%;
   height: 32px;
   width: 32px;
   background: #0082CA;
   text-align: center;
   display:flex;
   justify-content: center;
   align-items: center;
}
.video div svg{
   width: 8.35px;
}