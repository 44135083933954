@import "../../../assets/scss/utils/variables.scss";
.icon{
   display:flex;
   justify-content: center;
   align-items: center;
   height: 32px;
   width: 32px;
   border-radius:50%;
   background-color:$color-secondary-light-blue;

   &:hover{
      background-color:$color-main-blue;
      &>svg{
         path{
            fill:$color-white;
         }
      }
   }
}