@import "../../../../assets/scss/utils/variables";

.card{
   // width:552px;
   width:clamp(20rem, 10.3333rem + 20.1389vw, 34.5rem);
   aspect-ratio:9/8;
   position: relative;

   img{
      width: 100%;
      height:100%;
      object-fit: cover;
   }
   div{
      position:absolute;
      transition: all 400ms;
      bottom:0;
      padding: clamp(1.5rem, 0.8333rem + 1.3889vw, 2.5rem);
      width:100%;
      box-sizing: border-box;
      background-color: $color-main-text;
      opacity: 0.94;
      display:flex;
      flex-direction: column;
      gap:8px;

      h3{
         font-family: EuclidCircularB;
         font-style: normal;
         font-weight: 600;
         font-size: clamp(1.125rem, 0.875rem + 0.5208vw, 1.5rem);
         line-height: 30px;
         letter-spacing: -0.3px;
         color: $color-white;
      }
      p{
         font-family: EuclidCircularB;
         font-style: normal;
         font-weight: 400;
         font-size: clamp(0.875rem, 0.7083rem + 0.3472vw, 1.125rem);
         line-height: 30px;
         color: $color-pastel-blue;
      }
   }

   &:hover{
      div{
         background-color:$color-water-blue;
      }
   }
}