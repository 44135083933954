@import '../../assets/scss/utils/variables.scss';
.parent{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}
.lead {
   padding-top: 180px;
   width: 100%;
   height: 100%;

   .container{
    background-color: $color-white;
    padding: clamp(1.5rem, -1.0033rem + 5.2151vw, 4rem) 0px clamp(4rem, -2.0078rem + 12.5163vw, 10rem) 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 18px;

    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      gap: 18px;
    }
   }
 }

 @media (max-width: $breakpoint-md) {
   .lead{
      background-color: $color-grey !important;
      padding-top: 140px;
      .content {
        justify-content: center !important;
      }
      &>h1{
        color: $color-ebony-clay;
      }
      &>div{
        &:nth-child(2){
          display:none;
        }
        &:last-child{
          margin-top: 24px;
          padding: 56px 24px;
          h1{
            padding:0px;
          }
        }
      }
   }
   .parent{
      &+footer{
        background-color: $color-white;
      }
   }
 }
