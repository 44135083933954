@import '../../../assets/scss/utils/variables.scss';
.iframe{
   position:fixed;
   top:100px;
   z-index: 40;
   justify-self: center;
   width:calc(100% - 32px);
   max-width:997px;
   padding:8px;
   aspect-ratio: 9/5;
   box-sizing: border-box;
   iframe{
     width:100%;
     height:100%;
   }
   span{
      cursor: pointer;
      top:-28px;
      right:8px;
      position:absolute;
      z-index: 40;
      width:89px;
      height:36px;
      padding:8px 12px;
      display:flex;
      gap:4px;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $color-water-blue;
      align-items: center;
      background-color:$color-white;
      svg{
         path{
            stroke: $color-water-blue;
         }
      }
   }
 }