@import '../../../assets/scss/utils/variables.scss';
.breadcrumb-container{
   width: 100%;
   display: flex;
   justify-content: center;
   align-items: center;
   column-gap: 8.5px;

   a{
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: $color-white;
   }
   div{
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 8.5px;
   }
}