@import "../../../assets/scss/utils/variables";

@media (min-width:$breakpoint-md) {
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 352px;
    background-image: url("../../../assets/images/innerPageBackground.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $color-water-blue !important;
    z-index: -1;
  }
}
@media (max-width:$breakpoint-md) {
  .bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    min-height: 200px;
    background-image: none;
    background-color: $color-grey !important;
    z-index: -1;
  }
}
