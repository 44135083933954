@import '../../../assets/scss/utils/variables.scss';
.container{
    display:flex;
    overflow: hidden;
    flex-direction:column;
    background-color: $color-white;
    padding: clamp(2.5rem, -1.0046rem + 7.3012vw, 6rem) 0px 170px 24px !important;
    width: 100%;
    gap:40px;
   .team{
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 100%;
      h3{
         font-weight: 600;
         font-size: 20px;
         line-height: 24px;
         color: $color-main-text;
      }
   }
}
