.hero {
  height: clamp(26.25rem, 20.5rem + 28.75vw, 55rem);
  width: 100%;
  margin-top: 124px;
  position: relative;
  padding: 0;
  overflow: hidden;

  .main-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center ;
    background-repeat: no-repeat;
    background-size: cover;
    opacity: 1;

    &.active {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(1.3);
      animation: heroZoom 20s linear;
      animation-iteration-count: unset;
    }
  }

  .bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;

    &.active {
      opacity: 1;
    }

    .slide {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      animation: singleSlide 12s linear;
      animation-iteration-count: infinite;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;

      &:nth-child(20) {
        animation-delay: .6s;
      }

      &:nth-child(19) {
        animation-delay: 1.2s;
      }

      &:nth-child(18) {
        animation-delay: 1.8s;
      }
      &:nth-child(17) {
        animation-delay: 2.4s;
      }
      &:nth-child(16) {
        animation-delay: 3s;
      }

      &:nth-child(15) {
        animation-delay: 3.6s;
      }

      &:nth-child(14) {
        animation-delay: 4.2s;
      }

      &:nth-child(13) {
        animation-delay: 4.8s;
      }

      &:nth-child(12) {
        animation-delay: 5.4s;
      }

      &:nth-child(11) {
        animation-delay: 6s;
      }

      &:nth-child(10) {
        animation-delay: 6.6s;
      }

      &:nth-child(9) {
        animation-delay: 7.2s;
      }

      &:nth-child(8) {
        animation-delay: 7.8s;
      }
      &:nth-child(7) {
        animation-delay: 8.4s;
      }
      &:nth-child(6) {
        animation-delay: 9s;
      }
      &:nth-child(5) {
        animation-delay: 9.6s;
      }
      &:nth-child(4) {
        animation-delay: 10.2s;
      }
      &:nth-child(3) {
        animation-delay: 10.8s;
      }
      &:nth-child(2) {
        animation-delay: 11.4s;
      }
      &:nth-child(1) {
        animation-delay: 12s;
      }
    }
  }

  .icon {
    position: absolute;
    right: clamp(2rem, -2.725rem + 23.625vw, 25.625rem);
    top: clamp(4rem, 1.6125rem + 11.9375vw, 15.9375rem);
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      &:first-child {
        width: clamp(4rem, 3.4rem + 3vw, 7rem);
        height: clamp(4rem, 3.4rem + 3vw, 7rem);
        position: absolute;
      }

      &:last-child {
        width: clamp(6.5rem, 5.55rem + 4.75vw, 11.25rem);
        height: clamp(6.5rem, 5.55rem + 4.75vw, 11.25rem);
        z-index: 0;
        animation: rotating 8s linear infinite;

        &.active {
          animation: rotating 4s linear infinite;
        }
      }
    }
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes heroZoom {
  0% {
    transform: translate(-50%, -50%) scale(1);
  }
  100% {
    transform: translate(-50%, -50%) scale(1.3);
  }
}

@keyframes singleSlide {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  5% {
    opacity: 1;
    transform: scale(1.02);
  }
  6% {
    opacity: 0;
    transform: scale(1.02);
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

@media (max-width: 768px) {

  .hero{
    margin-top: 70px;
  }
}  