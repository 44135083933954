@import '../../assets/scss/utils/variables.scss';
.container{
   &>div{
      &:nth-of-type(1){
         display: flex;
         justify-content: flex-end;
      }
      &:nth-of-type(2){
         display: flex;
         width: 100%;
         box-sizing: border-box;
         justify-content: center;
         gap: clamp(1.5rem, -0.5026rem + 4.1721vw, 3.5rem);
         padding: 0px 16px clamp(4rem, -13.8983rem + 37.2881vw, 21.875rem) 16px;
         div{
            color:$color-secondary-text;
            h2{
               font-weight: 600;
               font-size: clamp(1.5rem, -0.5026rem + 4.1721vw, 3.5rem);
               line-height: clamp(1.875rem, -0.7534rem + 5.4759vw, 4.5rem);
               letter-spacing: -0.01em;
            }
            p{
               width: 100%;
               max-width: 520px;
               font-weight: 400;
               font-size: clamp(0.9375rem, 0.7498rem + 0.3911vw, 1.125rem);
               line-height: clamp(1.75rem, 1.2493rem + 1.043vw, 2.25rem);
               opacity: 0.8;
            }
         }
         figure{
            width: 50%;
            max-width:632px;
            img{
               width: 100%;
               object-fit: cover;
            }
         }
      }
   }
   &>p{
      display: none;
   }
   &>figure{
      display: none;
   }
 
}

@media (max-width: $breakpoint-md) {
   .container{
      &>div{
         &:nth-of-type(1){
            display: none;
         }
         &:nth-of-type(2){
            flex-direction: column;
         }
         &:last-child{
            padding: 56px 24px;
            h1{
               padding-bottom: 0px;
            }
         }
      }
      &>p{
         display: block;
         font-style: normal;
         font-weight: 400;
         font-size: 15px;
         line-height: 28px;
         color:$color-secondary-text;
         padding:120px 16px 0px 16px;
      }
      &>figure{
         display: flex;
         align-items: center;
         justify-content: center;
         position: relative;
         width: 100%;
         margin: 40px 0px 50% 0px;
         img{
            display: block;
            &:first-child{
               width:100%;
            }
            &:last-child{
               position: absolute;
               width: 80%;
               bottom: -50%;
            }
         }
      }
      &+footer{
         background-color: $color-white;
      }
   }
}