@import "../../../assets/scss/utils/variables.scss";
.contact-icon{
   position: relative;
   width: clamp(2.5rem, 2.2211rem + 1.5936vw, 3.75rem);
   height: clamp(2.5rem, 2.2211rem + 1.5936vw, 3.75rem);
   min-width: 40px;
   border-radius: 50%;
   background-color:$color-water;
   display: flex;
   align-items: center;
   justify-content: center;
}