 .mySwiper-event{
   background: #F5F6FA;
   width: 100%;
   height: 100%;
   margin:0px;
   padding: 0px;
   display: flex;
   align-items: center;
}
.mySwiper-event>div{
   background: #F5F6FA;
   height: 100%;
   display: flex;
}
.mySwiper-event>div>div{
   background: #F5F6FA;
   height: 100%;
}
.mySwiper-event>div>div>div{
   height: 100%;
   min-height:272px;
}
@media (max-width:768px){
   .mySwiper-event>div>div{
     display: flex;
     justify-content: flex-start;
     align-items: flex-start;
     max-width: clamp(16.25rem, 12.0833rem + 13.8889vw, 18.75rem)!important;
   }
 }
 