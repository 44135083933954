@import '../../../assets/scss/utils/variables.scss';

.input-div {
  position: relative;

  & > input {
    width: 100%;
    padding: 10px 24px;
    border: 1px solid $color-gainsboro;
    font-weight: 400;
    height: 56px;
    font-size: 16px;
    line-height: 20px;
    transition: all 400ms;

    &:focus {
      border-color: $color-main-blue;
    }
  }

  & > span {
    position: absolute;
    pointer-events: none;
    transition: 0.2s ease all;
    font-weight: 400;
    color: $color-regent-grey;
  }

  .input-empty {
    left: 28px;
    top: 18px;
    font-size: 16px;
    line-height: 20px;
  }

  .input-value {
    top: 8px;
    left: 24px;
    font-size: 12px;
    line-height: 14px;
  }

  & > input:focus ~ span {
    top: 8px;
    left: 24px;
    font-size: 12px;
    line-height: 14px;
  }
}