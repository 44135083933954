@import "../../../assets/scss/utils/variables";
.parent{
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  width: 100%;
  &>div:first-child{
    position: absolute;
    top:100px;
    width: calc(100% - 32px );
    max-width: clamp(17.5rem, 8.8546rem + 49.4024vw, 56.25rem);
    background-color: white;
  }
}
.events {
  background: $color-grey;
  width: 100%;
  .content {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 1.4733rem + 2.6337vw, 4rem);
    padding: clamp(3.5rem, 1.7881rem + 8.5597vw, 10rem) 0;

    .header {
      display: flex;
      justify-content: space-between;

      a {
        width: 209px;
        height: 56px;
        display: flex;
        align-items: center;
      }
    }

    .body {
      display: flex;
      justify-content: center;
      width: 100%;
      flex-wrap: wrap;
      gap: 18px;
      p{
        text-align: left;
      }
    }
  }
}
.read-more-bottom{
  width: 248px;
  height: 56px;
  border: 1px solid $color-cloud;
  background-color: $color-white;
  display: flex;
  justify-content:center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $color-secondary-text;
  margin-left: 0px;
}
@media (max-width: $breakpoint-md) {
  .header{
    justify-content: center !important;
    a{
      display: none !important;
    }
  }

}