@import "../../../assets/scss/utils/variables";

.athletes {
  display: flex;
  width: 100%;
  aspect-ratio: 2/1;
  box-sizing: border-box;
  margin: 0px;
  padding: 0px;

  .left {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    box-sizing: border-box;

    .img {
      padding: 0px;
      margin: 0px;
      display: block;
      width: calc((100%) / 2);
      height: calc((100%) / 2);
      // width: calc((100%) / 3);
      // height: calc((100%) / 3);
      aspect-ratio: 1/1;
      object-fit:cover;


        img {
          width: 100%;
          height :100%;
          margin: 0px;
          display: block;
          padding: 0px;
          object-fit:cover;
        }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    width: 50%;
    padding: 0px;
    background: $color-secondary-light-blue;
    justify-content: space-between;

    .top {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 50%;
      padding-left: 110px;
      justify-content: center;

      .top-inside {
        display: flex;
        flex-direction: column;
        row-gap: 38px;

        .button {
          width: 200px;
          height: 56px;
        }
      }
    }

    .bottom {
      width: 100%;
      height: 50%;
    }
  }
}

@media (max-width: $breakpoint-lg) {
  .athletes {
    aspect-ratio: 1;
    width: 100%;
    flex-direction: column-reverse;

    .right {
      width: 100%;
    }

    .left {
      width: 100%;
      height: 100%;
      .img {
      height: 50% !important;
      margin: 0px; padding: 0px;
      }

      .img:nth-of-type(7) {
        display: none !important;
      }

      .img:nth-of-type(8) {
        display: none !important;
      }

      .img:nth-of-type(9) {
        display: none !important;
      }

    }

    .right {
      .top {
        padding-left: 32px;
      }

      padding-top: 72px;

      img {
        display: none;
      }
    }
  }
}

@media (max-width: $breakpoint-sm) {
  .athletes {
    .left {
      .img {
        width: calc((100%) / 2) !important;
          height: calc((100%) / 3);
      }

    }

    .right {
      padding-top: 72px;

      img {
        display: none;
      }
    }
  }
}