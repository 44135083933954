@import "../../assets/scss/utils/variables.scss";

.container {

   h2{
      font-size: clamp(1.625rem, 0.375rem + 2.4042vw, 3.5rem) !important;
   }
   .federation{
    p{
     max-width: 600px !important;
    }
   }

  & > div {
    &:first-child {
      display: none;
    }
    &:nth-of-type(2) {
      display: none;
    }
    &:nth-of-type(3) {
      display: flex;
      justify-content: flex-end;
    }
  }
  & > p {
    display: none;
  }
  & > figure {
    display: none;
  }
}
@media (max-width: $breakpoint-md) {
  .container {
    & > div {
      &:first-child {
        display: block;
      }
      &:nth-of-type(3) {
        display: none;
      }
      &:last-child {
        padding: 56px 24px;
        h1 {
          padding-bottom: 0px;
        }
      }
    }
    .text {
      padding-top: 120px;
      width: 100%;
      display: flex !important;
      justify-content: center;
      align-items: center;
      h1 {
        color: $color-ebony-clay;
      }
    }
    & > p {
      display: block;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      color: $color-secondary-text;
      padding: 0px 16px;
    }
    & > figure {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      width: 100%;
      margin: 40px 0px 50% 0px;
      img {
        display: block;
        &:first-child {
          width: 100%;
        }
        &:last-child {
          position: absolute;
          width: 80%;
          bottom: -50%;
        }
      }
    }
    & + footer {
      background-color: $color-white;
    }
  }
}
