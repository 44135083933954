@import "./assets/scss/reset/reset.css";
@import "./assets/scss/utils/variables.css";

@keyframes logo {
    from {
      opacity: 1;
    }
    to {
      opacity: 0.6;
    }
}

html,
body {
    scroll-behavior: smooth;
}

.w-100 {
    width: 100% !important;
}

.container {
    margin: 0 auto;
    max-width: 1128px;
}

.loading{
  position: fixed;
  top: 0;
  z-index: 13;
  left: 0;
  width: 100%;
  height: 100% !important;
  display: flex;
  justify-content:center;
  align-items: center;
  background-color: #fff;
}

.loading>img{
    animation: logo 1s linear infinite alternate;
}

@media screen and (max-width: 992px) {
    .container {
        width: calc(100% - 48px);
    }
}

@media screen and (min-width: 992px) {
    .container {
        width: calc(100% - 200px);
    }
}

@media screen and (min-width: 1400px) {
    .container {
        width: calc(100% - 400px);
    }
}

@media screen and (min-width: 1920px) {
    .container {
        width: calc(100% - 792px);
    }
}
