@import '../../../assets/scss/utils/variables.scss';

.footer-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  & > div:first-child {
    margin: clamp(3rem, 1.4198rem + 7.9012vw, 9rem) 40px clamp(3rem, 2.6049rem + 1.9753vw, 4.5rem) 40px;
    display: flex;
    column-gap: 53px;

    & > div:first-child {

      & > div {
        margin: 28px 0px 0px 0px;
        display: flex;
        column-gap: 24px;
      }
    }

    & > div:last-child {
      display: flex;
      column-gap: 88px;
    }
  }

  & > div:last-child {
    width: 100%;
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top: 1px solid $color-gainsboro;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: $color-secondary-text;
    opacity: 0.8;
  }
}

.bg-white {
  background-color: $color-white;
}

.bg-grey {
  background-color: $color-grey;
}

.column-container {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  & > p {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    & > * {
      font-weight: 400;
      font-size: 16px;
      line-height: 40px;
      color: $color-secondary-text;
      text-decoration: none;
    }

  }
}

@media (max-width: $breakpoint-lg) {
  .column-container{
    display:none;
  }
  .footer-container {
  
    & > div:first-child {
      width:240px;
      &>div:first-child{
        width: 100%;
        &>div{
          display: flex;
          width: 100%;
          justify-content: center !important;
          align-items: center !important;
          column-gap: 16px;
        }

      }
    }
  }
}