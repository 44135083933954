@import "../../../assets/scss/utils/variables";
.appeal-container {
   margin: 0px;
   padding: clamp(0rem, -8.0172rem + 12.931vw, 7.5rem) 0px;
   width: 100%;
   height: auto;
   background: $color-grey;
   display: flex;
   justify-content: center;
   align-items: center;
   & > div {
      padding: clamp(3.5rem, 3.4rem + 0.5vw, 4rem) clamp(1.5rem, -2.2414rem + 6.0345vw, 5rem);
      background: $color-white;
      display: flex;
      align-items: center;
      column-gap: clamp(1.5rem, -1.1262rem + 4.2357vw, 2.9375rem);

      & > div:first-child {

         & > div:nth-of-type(1) {
            margin:  clamp(2rem, 1.6rem + 2vw, 4rem) 0px 0px 0px;
            & > div {
               display: flex;
               column-gap: 12px;
               align-items: center;
               margin: 0px 0px 32px 0px;
               p{
                  font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
                  line-height: clamp(1.25rem, 1.125rem + 0.625vw, 1.875rem);
               }
            }
         }
         & > div:nth-of-type(2) {
            margin: 12px 0px 0px 0px;
            padding: clamp(1.125rem, 0.4399rem + 1.105vw, 1.5rem) clamp(1.5rem, 0.5866rem + 1.4733vw, 2rem);
            background: $color-grey;
            display: flex;
            column-gap: 36px;
            align-items: center;

            & > p {
               width: 181px;
               font-size: 20px;
               font-weight: 600;
               color: $color-secondary-text;
            }

            & > div {
               display: flex;
               column-gap: 24px;
            }
         }
         &>a{
            display: none;
         }
      }
      & > div:last-child {
         padding: clamp(2rem, 1.4655rem + 0.8621vw, 2.5rem) clamp(1.25rem, 0.4483rem + 1.2931vw, 2rem);
         background: $color-grey;
         width: 456px;
         height: 516px;
         & > form {
            display: flex;
            flex-direction: column;
            row-gap: 24px;

            & > select {
               width: 100%;
               padding: 18px 24px;
               border: 1px solid $color-gainsboro;
               background: $color-white;
               font-weight: 400;
               height: 56px;
               font-size: 16px;
               line-height: 20px;
               color: $color-regent-grey;
               -webkit-appearance: none;
               appearance: none;
               -moz-appearance: none;
               background-image: url("https://www.svgrepo.com/show/31769/down-arrow.svg");
               background-repeat: no-repeat;
               background-size: 8px 8px;
               background-position: calc(100% - 24px);
            }
         }
      }
   }
}

@media (max-width: $breakpoint-lg){
   .appeal-container{
      &>div{
         &>div:first-child{
            h1{text-align: center;}
            & > div:nth-of-type(2) {
               display : none;
            }
            a{
               display : block;
               width:100%;
            }
         }
         &>div:last-child{
           display : none;
         }
      }
   }
}
@media (max-width: $breakpoint-large) {
   .appeal-container{
      background-color: $color-white;
   }
}