@import "../../../assets/scss/utils/variables";

@keyframes hoverscale{
  from{
    transform:scale(1);
  }
  to{
    transform:scale(1.15);
  }
}
@keyframes arrow {
  from{
       margin-left: 0px;
  }
  to{
       margin-left: 10px;
  }
}
.school {
  position: relative;
  width: 100%;
  background: linear-gradient(180deg, rgba(4, 52, 101, 0.8) -5.38%, rgba(4, 52, 101, 0) 121.5%);
  height: 576px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: hidden;
  transition: all 3s;
  .video {
    transition: all 3s;
    position: absolute;
    left: 0;
    top: 0;
    background: url("../../../assets/images/school-bg.svg");
    width: 100%;
    height: 100%;
    background-size:     cover;   
    background-repeat:   no-repeat;
    background-position: center center;
    z-index: -1;
  }
  .content {
    transition: all 3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
    row-gap: 64px;
    height:300px;
    position: relative;
    width: 100%;
    width: 800px;
    
    &>header {
      transition: all 3s;
      display: flex;
      flex-direction: column;
      row-gap: 48px;
      width: 100%;
      top:0px;
      right:0px;
      position:absolute;
      animation-play-state: paused;

      h1 {
        transition: all 3s;
        text-align: center;
        text-transform: uppercase;
        color: $color-white;
        font-weight: 700;
        font-size: clamp(1.5rem, 1.2rem + 1.5vw, 3rem);
        line-height: clamp(1.875rem, 1.4875rem + 1.9375vw, 3.8125rem);
      }

      p {
        transition: all 3s;
        color: $color-white;
        max-width: 950px;
        font-size: clamp(1.125rem, 0.95rem + 0.875vw, 2rem);
        line-height: clamp(1.5rem, 1.2875rem + 1.0625vw, 2.5625rem);
        text-align: center;
        text-shadow: 0px 0px 80px #000000;
      }
    }

  .icon {
    transition: all 3s;
    display: flex;
    width: clamp(4rem, 3.3rem + 3.5vw, 7.5rem);
    height: clamp(4rem, 3.3rem + 3.5vw, 7.5rem);
    position: absolute;
    bottom:0px;
    left:45%;
    line-height: 120px;
    background: rgba(255, 255, 255, 0.24);
    backdrop-filter: blur(4px);
    border-radius: 50%;
    cursor: pointer;
    justify-content:center;
    align-items: center;
    svg{
      width: clamp(1.04375rem, 0.8612rem + 0.9125vw, 1.95625rem);
    }
  }
  }
  .info{
    transition: all 3s;
    position: absolute;
    right:-100%;
    z-index:2;
    height: 100%;
    width: 50%;
    background:$color-white;
    display: flex;
    flex-direction: column;
    row-gap: clamp(1.25rem, -0.0862rem + 2.1552vw, 2.5rem);
    justify-content:center;
    padding:
     clamp(2.8125rem, -0.0603rem + 4.6336vw, 5.5rem) 
     clamp(3.875rem, 0rem + 6.25vw, 7.5rem) 
     clamp(3.4375rem, -0.0366rem + 5.6034vw, 6.6875rem)
     clamp(3.875rem, 0rem + 6.25vw, 7.5rem) 
     ;

    h6{
      font-size: clamp(2.125rem, 1.5905rem + 0.8621vw, 2.625rem);
      justify-self: unset;
      align-self: flex-start;
      font-weight: 600;
      line-height:  clamp(2.625rem, 1.4224rem + 1.9397vw, 3.75rem);
      letter-spacing: -1px;
      color:$color-ebony-clay;
    }
    p{
      font-size: clamp(0.75rem, 0.65rem + 0.5vw, 1.25rem);
      font-weight: 400;
      width:100%;
      padding-right:12px;
      line-height: 32px;
      letter-spacing: 0px;
      color:$color-secondary-text;
      box-sizing: border-box;
    }
    a{
      width:175px;
    }
  }
}

@media (min-width: $breakpoint-lg){
  .school{
    &:hover{
      &>.video{
        animation: hoverscale 3s forwards;
        left: -23%;
      }
      &>.content{
        &>header{
          right:100vw;
        }
        &>.icon{
          bottom:100px;
          left:0;
        }
      }
      &>.info{
        right: 0;
      }
    }
  }
}