@import '../../assets/scss/utils/variables.scss';
.athletes{
   padding-top:200px;
   box-sizing: border-box;
   .tabs-container{
      width:100%;
      display: flex;
      justify-content: center;
      align-items:flex-start;
      margin:96px 0px 170px 0px;
      box-sizing: border-box;
   }
   &>div:nth-of-type(3){
     margin-top: clamp(2rem, -1.0039rem + 6.2581vw, 5rem);
   }
}

@media (max-width:$breakpoint-md) {
   .athletes{
      padding-top: 130px;
      &>div{
        &:last-child{
           padding: 56px 24px;
           position: relative;
           h1{
              padding-bottom: 0px;
           }
        }
     }
     &+footer{
      background-color: $color-white;
     }
   }
   .text{
     &>div{
       display: none;
     }
     h1{
       color: $color-ebony-clay;
     }
   }
 }
