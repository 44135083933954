@import '../../../assets/scss/utils/variables.scss';

.textarea{
   height: 116px;
   padding: 18px 24px;
   border: 1px solid $color-gainsboro;
   box-sizing: border-box;
   resize:none;
   transition: all 400ms;

   &:focus {
     border-color: $color-main-blue;
   }

   &::-webkit-input-placeholder {
      color: $color-regent-grey;
      opacity: 1;
      font-size: 16px;
      font-weight:400;
    }
    
   &:-moz-placeholder {
      color: $color-regent-grey;
      opacity: 1;
      font-size: 16px;
      font-weight:400;  
    }
    
   &::-moz-placeholder {  
      color: $color-regent-grey;
      opacity: 1;
      font-size: 16px;
      font-weight:400;  
    }
    
   &:-ms-input-placeholder {
      color: $color-regent-grey;
      opacity: 1;
      font-size: 16px;
      font-weight:400;  
    }
    
   &::placeholder {
      color: $color-regent-grey;
      opacity: 1;
      font-size: 16px;
      font-weight:400;  
    }
}