@import '../../assets/scss/utils/variables.scss';

.container{
   padding-top: 200px;

   &>h1{
     margin-bottom:40px;
   }

   .content{
      padding:
      80px
      clamp(1rem, -22.781rem + 49.5437vw, 24.75rem)
      136px
      clamp(1rem, -22.781rem + 49.5437vw, 24.75rem);
      width: 100%;
      background-color:$color-white;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: clamp(2rem, 0.6667rem + 2.7778vw, 4rem);

      &>h1{
         font-family: EuclidCircularB;
         font-style: normal;
         font-weight: 500;
         font-size: 24px;
         line-height: 30px;
         text-align: center;
         color: $color-main-text;
      }
      &>ul{
         display: flex;
         flex-direction: column;
         gap: clamp(2rem, 1.4167rem + 1.2153vw, 2.875rem);
         margin-left: 16px;

         li{
            list-style-image: url('../../assets/images/list.svg');
            padding-left: 16px;
            cursor: pointer;
            &>h2{
               font-family: EuclidCircularB;
               font-style: normal;
               font-weight: 500;
               font-size: 18px;
               line-height: 24px;
               color: $color-main-text;
            }
            &>div{
               display: flex;
               align-items: flex-end;

               &>p{
                  font-family: EuclidCircularB;
                  font-style: normal;
                  display:inline-block;
                  padding: 0px !important;
                  width:100%;
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 30px;
                  color: $color-secondary-text;
                  text-align: justify;
                  text-justify: inter-word;
                  height: 30px;
                  overflow: hidden;
                  margin-right: 5px;
               }
            }

         }
      }
   }
}
@media (max-width: $breakpoint-md) {
   .container{
     h1{
       color: $color-ebony-clay;
       margin-top:-70px;
       padding-bottom:0px;
     }
     .content{
        padding-top: 100px;
        &>ul{
           div{
              display:block !important;
            p{
               text-align: initial !important;
               text-justify: initial!important;
               height: 100% !important;
               max-height:120px !important;
            }
           }

        }
     }
     &+footer{
        background-color: $color-white;
     }
   }
 } 

