@import "../../../assets/scss/utils/variables";

.header_container {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  background: $color-white;
  z-index: 13;
  box-shadow: 0px -2px 0px 0px #D8DCE0 inset;

  .line{
    width: 100%;
    height:6px;
    background:$color-water-blue;
    z-index: 5;
  }
  .header-contact{
    background: $color-white;
    height:56px;
    width: 100%;
    box-shadow: 0px -1px 0px 0px $color-platinum inset;


    &>.contact-container{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: clamp(46rem, 19.5833rem + 55.0347vw, 85.625rem);
      margin: 0 auto;

      &>div{
        &:first-child{
          display: flex;
          gap: 30px;
        }
        &:last-child{
          display: flex;
          gap:12px;
  
          div{
            &:nth-child(2){
              label{
                display: flex;
                align-items: center;
                svg{
                  margin:0px 6px;      
                }
                input{
                  width:100px;
                  padding-right: 20px;
                  outline: none;
                  border: none;
    
                  &::placeholder{
                    font-weight: 400;
                    font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                    line-height: 20px;
                    color: $color-regent-grey;  
                  }
                }
              }
  
            }
            &:nth-child(1){
              a{
                cursor: not-allowed;
                display: flex;
                gap:6px;
                svg{
                  opacity : 0.3;
                }
                p{
                  font-weight: 400;
                  font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                  line-height: 20px;
                  color: $color-regent-grey;
                }
              }
            }
            &:nth-child(3){
              a{
                display: flex;
                gap:6px;
                p{
                  font-weight: 400;
                  font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                  line-height: 20px;
                  color: $color-regent-grey;
                }
              }
            }
            &:last-child{
              width:62px;
              position: relative;
              display: flex;
              justify-content:center;
  
              &:focus-within{
                transition: all 400ms;
                span{
                  svg{
                    transition: all 400ms;
                    transform: rotate(-180deg);
                  }
                }
              } 
              ul{
                transition: all 400ms;
                flex-direction: column;
                top:24px;
                position:absolute;
                z-index: 2;
                background-color: $color-white;
                border-radius:12px;
                box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  
                li{
                  background:$color-white;
                  font-weight: 400;
                  font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                  line-height: 20px;
                  color: $color-regent-grey;
                  padding:8px;
  
                  
                  &:first-child{
                    border-top-left-radius: 12px;
                    border-top-right-radius: 12px;
                  }
                  &:last-child{
                    border-bottom-left-radius: 12px;
                    border-bottom-right-radius: 12px;
                  }
  
                  &:hover{
                    cursor: pointer;
                    color: $color-white;
                    background:$color-regent-grey;
                  }
                }
              }
              span{
                background:$color-white;
                font-weight: 400;
                font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                line-height: 20px;
                align-items: center;
                color: $color-regent-grey;
                display: flex;
                gap: 5px;
                cursor: pointer;
  
                  svg{
                    transition: all 400ms;
                  }
              }
  
            }
  
          }
  
        }
      }
    }
  }

  &.transparent {
    background-color: transparent;

    .header {
      &>.menu {
        &>li {
          &>a {
            color: $color-white;

            &:hover
            {
              color: $color-white;

              &::after {
                background: url("../../../assets/images/white-wave.svg") no-repeat;
                background-size: cover;
                opacity: 1;
                visibility: visible;
                left: 0;
              }
            }
          }
        }
      }

      .button-group {

        .button {
          background-color: transparent;
          border: 1px solid $color-white;
          position: relative;

          &::after {
            content: "";
            position: absolute;
            left: -40px;
            top: 10px;
            width: 87px;
            height: 1px;
            background-color: $color-white;
            transform: rotate(73deg);
          }
        }

        .menu-icon {
          border: 1px solid $color-main-blue;
          background-color: $color-white;
          transition: all 400ms;

          svg {
            transform: translateY(3px);
          }

          &:hover {
            opacity: 1;
          }
        }
      }

    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: clamp(46rem, 19.5833rem + 55.0347vw, 85.625rem);
    margin: 0 auto;
    padding: clamp(0.75rem, -0.5rem + 1.6667vw, 1.5rem) 0;

    .logo {
      width:clamp(7.375rem, -29.5rem + 49.1667vw, 14.75rem);
      height: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .menu {
      display: flex;
      align-items: center;
      column-gap: 32px;

      &>li {
        transition: all 400ms;
        &>a {
          font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
          line-height: 20px;
          color: $color-secondary-text;
          transition: all 400ms;
          position: relative;
          &::after {
            content: "";
            position: absolute;
            opacity: 0;
            visibility: hidden;
            left: -100%;
            bottom: -10px;
            width: 100%;
            height: 10px;
            transition: all 600ms;
          }
          &:hover{
            color: $color-main-text;

            &::after {
              // background: url("../../../assets/images/wave.svg") no-repeat;
              background: url("../../../assets/images/blue-line.svg") no-repeat;
              background-size: cover;
              opacity: 1;
              visibility: visible;
              left: 0;
            }
          }
        }
        position: relative;
        .dropdown{
          opacity: 0;
          transition: all 400ms;
          display: none;
          position: absolute;
          box-sizing: border-box;
          flex-direction: column;
          align-items: flex-start;
          width: max-content;
          min-width: clamp(9.1875rem, -6.4841rem + 20.8955vw, 13.5625rem);
          height: auto;
          background: $color-white;
          border: 1px solid $color-gainsboro;
          top : clamp(1.75rem, 1.3022rem + 0.597vw, 1.875rem);
          left: -30px;
          &>li{
            position: relative;
            border-top: clamp(0.1875rem, -0.4841rem + 0.8955vw, 0.375rem) solid $color-white;
            border-bottom: clamp(0.1875rem, -0.4841rem + 0.8955vw, 0.375rem) solid $color-white;
            border-right:  clamp(0.3125rem, -1.2547rem + 2.0896vw, 0.75rem) solid $color-white;
            border-left:  clamp(0.3125rem, -1.2547rem + 2.0896vw, 0.75rem) solid $color-white;
            width : 100%;
            height: clamp(2.125rem, 0.7482rem + 2.8683vw, 3.5rem);
            box-sizing: border-box;
            display:flex;
            align-items: center;

            &>a{
              width : 100%;
              display:flex;
              align-items: center;
              padding-left: 12px;
              height:100%;
              font-weight: 500;
              font-size: clamp(0.625rem, -0.7183rem + 1.791vw, 1rem);
              line-height: clamp(0.8125rem, -0.7547rem + 2.0896vw, 1.25rem);
              color: $color-main-text;
            }
            .submenu{
              opacity: 0;
              transition: all 400ms;
              display : none;
              position: absolute;
              box-sizing: border-box;
              flex-direction: column;
              align-items: flex-start;
              padding: clamp(0.1875rem, -0.9319rem + 1.4925vw, 0.5rem) clamp(0.3125rem, -1.2547rem + 2.0896vw, 0.75rem);
              width: max-content;
              min-width: clamp(9.1875rem, -6.4841rem + 20.8955vw, 13.5625rem);
              height: auto;
              background: $color-white;
              border: 1px solid $color-gainsboro;
              right: clamp(-14.3125rem, 7.4524rem + -22.6866vw, -9.5625rem);
              top: clamp(-0.4375rem, 0.4216rem + -0.8955vw, -0.25rem);
              &>li{
                position: relative;
                width : 100%;
                height: clamp(2rem, 1.249rem + 1.5645vw, 2.75rem);
                box-sizing: border-box;
                display:flex;
                align-items: center;
                &:hover{
                  background-color: $color-water-blue;
                  a{
                   color: $color-white;
                  }
                }
                &>a{
                  width : 100%;
                  display:flex;
                  align-items: center;
                  padding-left: 12px;
                  height:100%;
                  font-weight: 500;
                  font-size: clamp(0.625rem, -0.7183rem + 1.791vw, 1rem);
                  line-height: clamp(0.8125rem, -0.7547rem + 2.0896vw, 1.25rem);
                  color: $color-main-text;
                }
              }
            }
            &:hover{
              background-color: $color-water-blue;
              a{
               color: $color-white;
              }
             .submenu{
              transition: all 400ms;
              opacity: 1;
              display:flex;
             }
           }
          }
        }
        &:hover{
          transition: all 400ms;
          .dropdown{
            transition: all 400ms;
            opacity:1;
            display: flex;
          }
        }
      }
    }

    .button-group {
      display: flex;
      align-items: center;
      column-gap: 16px;
      .button {
        width: clamp(6.5rem, -14.125rem + 27.5vw, 10.625rem);
        height: clamp(2.25rem, -2.75rem + 6.6667vw, 3.25rem);
        display: flex;
        align-items: center;

        span{
          font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
          svg{
            width: clamp(0.8125rem, -2rem + 3.75vw, 1.375rem);
          }
        }
      }
    }
    .menu-icon{
      display: none;
      cursor: pointer;
    }
  }
}

@media  screen and (max-width:768px){
    .header-contact{
      display: none;
    }
    header.header{
      display: flex !important;
      align-items: center !important;
      justify-content: space-between !important;
      width:100% !important;
      margin: 0 auto !important;
      padding: 16px 21px !important;

      .logo{
        height: 56px !important;
        width: 194px !important;
      }
      .menu{
        display: none !important;
      }
      .button-group{
        display: none !important;
      }
      .menu-icon{
        display: block !important;
      }
    }

}