@import "../../../assets/scss/utils/variables";


.info {
  padding: clamp(3.5rem, 2.2rem + 6.5vw, 10rem) 0px ;
  background-color: $color-white;
  background-image: url("../../../assets/images/info-wave.svg");
  background-size:     cover;
  background-repeat:   no-repeat;
  background-position: center center;
  width: 100%;
  .content {
      align-items: center;
      display: flex;
      gap: clamp(3.5rem, -2.75rem + 8.3333vw, 7.25rem);

    .left {
      display: flex;
      align-items: center;
      justify-content: center;
      width:100%;
      height: 100%;
      height: clamp(13.375rem, 10.4rem + 14.875vw, 28.25rem);
      min-height: 214px;
      width: clamp(14.5rem, 10.8rem + 18.5vw, 33rem);
      min-width:232px;

      .icons {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          position: absolute;
          width: clamp(5.75rem, 4.275rem + 7.375vw, 13.125rem);
          height: clamp(5.5rem, 4.1rem + 7vw, 12.5rem);
          z-index: 1;
        }

        &::after {
          content: "";
          background: $color-white;
          width: clamp(8.5rem, 6.3rem + 11vw, 19.5rem);
          height: clamp(8.5rem, 6.3rem + 11vw, 19.5rem);
          border: 8px dotted #000;
          border-radius: 50%;
          position: absolute;
        }

        div {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: clamp(7.25rem, 5.4rem + 9.25vw, 16.5rem);
          height: clamp(7.25rem, 5.4rem + 9.25vw, 16.5rem);
          border: 2px solid $color-secondary-text;
          border-radius: 50%;
          text-align: center;
          transition: all 400ms;
          background: rgba(255, 255, 255, 0.2);
          backdrop-filter: blur(5px);

          span {
            transition: all 400ms ease-in-out;
            font-size: clamp(1.125rem, 1.0262rem + 0.4938vw, 1.5rem);
            line-height: 30px;
            color: $color-main-text;
            transform: scale(0);
          }

          &:hover {
            z-index: 1;

            span {
              transform: scale(1);
            }
          }

          &:nth-of-type(1) {
            left: auto;
            top: 0px;
          }

          &:nth-of-type(2) {
            left: 0px;
            bottom: 0px;
          }

          &:nth-of-type(3) {
            right: 0px;
            bottom: 0px;
          }
        }

        &.active {
          &::after {
            animation: rotating 14s linear infinite;
          }
        }
      }

    }

    .right {
      display: flex;
      flex-direction: column;
      row-gap: 40px;
      width: clamp(23.75rem, 13.3333rem + 13.8889vw, 30rem);

      .info-header {
        display: flex;
        flex-direction: column;
        row-gap: 40px;
        display: none;

        &.active {
          display: flex;
          animation: fadeIn 0.8s ease-in-out;
        }
        .description {
          font-size: clamp(1rem, 0.975rem + 0.125vw, 1.125rem);
          line-height:  clamp(2.125rem, 2.175rem + -0.25vw, 1.875rem);
          color: $color-secondary-text;
        }
      }

      .info-button {
        width: 175px;
      }
    }
  }
}
.hover {
  z-index: 1;

  span {
    transform: scale(1) !important;
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: $breakpoint-lg){
  .content{
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .right{
      width: clamp(17rem, 10.8095rem + 30.9524vw, 30rem) !important;
      h1{
        text-align: center;
      }
    }
  }
}