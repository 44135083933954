@import '../../assets/scss/utils/variables.scss';

.parent{
   display: flex;
   align-items: center;
   justify-content: center;

   section{
      width: 100vw !important;
   }
}
.home{
   overflow: hidden;
}
@media (max-width:$breakpoint-large) {
   .home+footer{
      background-color:$color-grey;
   }
}
@media (max-width:$breakpoint-md) {
   .parent+footer{
      background-color: $color-grey;
   }
}