@import "../../../../assets/scss/utils/variables";

.events-card {
  padding: clamp(1.5rem, 1.3683rem + 0.6584vw, 2rem);
  width: clamp(15.5rem, 13.6564rem + 9.2181vw, 22.5rem);
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  justify-content: space-between;
  border: 1px solid $color-light-grey;
  background: $color-white;
  transition: all 400ms;
  cursor: pointer;

  .body {
    display: flex;
    flex-direction: column;
    row-gap: 24px;

    .header {
      display: flex;
      flex-wrap: wrap;
      gap: 12px;

      span {
        padding: 6px 12px;
        font-size: 12px;
        line-height: 16px;
        transition: all 400ms;

        &:first-child {
          color: $color-main-blue;
          background: $color-light-blue;
        }

        &:last-child {
          color: $color-secondary-text;
          background: $color-light-grey;
        }
      }
    }

    .content {
      font-weight: 600;
      font-size: clamp(1.125rem, 1.0921rem + 0.1646vw, 1.25rem);
      line-height: 26px;
      color: $color-main-text;
      transition: all 400ms;
    }
  }

  .footer {
    a {
      color: $color-main-text;
      font-size: 16px;
      line-height: 24px;
      font-weight: 600;
      display: inline-flex;
      column-gap: 14px;
      align-items: center;
      transition: all 400ms;

      svg {
        transition: all 400ms;

        path {
          stroke: $color-main-blue;
        }
      }

      &:hover {
        svg {
          transform: translateX(10px);
        }
      }
    }
  }

  &:hover {
    background: $color-main-blue;

    .body {
      .header {
        span {
          color: $color-main-text;
          background: $color-white;
        }
      }

      .content {
        color: $color-white;
      }
    }

    .footer {
      a {
        color: $color-white;

        svg {
          path {
            stroke: $color-white;
          }
        }
      }
    }
  }
}