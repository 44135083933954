@import "../../../../assets/scss/utils/variables.scss";
.container{
   width: clamp(15.5rem, 8.4909rem + 14.6023vw, 22.5rem);
   height: clamp(22.5rem, 19.9967rem + 5.2151vw, 25rem);
   box-sizing: border-box;
   position: relative;
   cursor: pointer;
    img{
     width:100%;
     height:100%;
     object-fit: cover;
    }
    &:hover{
      &>div{
        background-color: $color-water-blue;
        span{
          color: $color-white;
        }
      }
    }
    &>div{
       transition: all 400ms;
       clip-path: polygon(0 0, 100% 35%, 100% 100%, 0% 100%);
       background-color: $color-main-text;
       width:100%;
       aspect-ratio: 2/1;
       position: absolute;
       bottom:0;
       padding : 32px clamp(1.25rem, -0.0016rem + 2.6076vw, 2.5rem);
       display: flex;
       flex-direction: column;
       align-items: flex-start;
       justify-content: flex-end;
       color: $color-white;
       gap:8px;

       p{
         font-style: normal;
         font-weight: 600;
         font-size: clamp(1.25rem, 0.9997rem + 0.5215vw, 1.5rem);
         line-height: clamp(1.5rem, 1.1245rem + 0.7823vw, 1.875rem);
         letter-spacing: -0.3px;
       }
       span{
         font-style: normal;
         font-weight: 400;
         font-size: clamp(1rem, 0.8748rem + 0.2608vw, 1.125rem);
         line-height: clamp(1.25rem, 0.6242rem + 1.3038vw, 1.875rem);
         color: $color-pastel-blue;
       }
       
    }

} 

.active-athlete{
   &>div{
     background-color: $color-water-blue;
     span{
       color: $color-white;
     }
   }
 }
 