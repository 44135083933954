@import '../../../assets/scss/utils/variables.scss';

@keyframes opacity {
   from { opacity:0;}
   to { opacity:1;}
}
.sidebar{
   overflow-y: auto;
   position: fixed;
   height: calc(100% - 93px);
   top: 94px;
   left:0px;
   width: 100%;
   z-index: 20;
   background-color: $color-white;
   animation : opacity 400ms;

   .menu{
      width: 100%;
      &>li{
         width: 100%;
         padding:20px 24px;
         box-shadow: 0px -1px 0px 0px $color-gainsboro inset;
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         &:hover{
            &>a{
               color: $color-water-blue;
               &>span{
                  &>svg{
                     transition: all 400ms;
                     transform: rotate(180deg);
                     &>path{
                        stroke: $color-water-blue;
                     }
                  }
               }
            }
            &>ul{
              display: flex;
            }
         }
         &>a{
            font-weight: 500;
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 16px;
            line-height: 20px;
            color: $color-main-text;
            &>span{
               svg{
                  transition: all 400ms;
                  path{
                     stroke: $color-main-text;
                  }
               }
            }
         }
      }
      .dropdown{
         display: none;
         flex-direction: column;
         &>li{
            width: 100%;         
            padding:20px 0px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            &:hover{
               &>a{
                  color: $color-water-blue;
                  &>span{
                     &>svg{
                        transition: all 400ms;
                        transform: rotate(180deg);
                        &>path{
                           stroke: $color-water-blue;
                        }
                     }
                  }
               }
               &>ul{
                 display: flex;
               }
            }
            &>a{
               font-weight: 500;
               width: 100%;
               display: flex;
               justify-content: space-between;
               font-size: 16px;
               line-height: 20px;
               color: $color-main-text;
               &>span{
                  svg{
                     transition: all 400ms;
                     path{
                        stroke: $color-main-text;
                     }
                  }
               }
            }
         }
         .submenu{
            display: none;
            flex-direction: column;
            &>li{
               width: 100%;         
               padding:20px 0px;
               display: flex;
               flex-direction: column;
               justify-content: space-between;
               &>a{
                  font-weight: 500;
                  width: 100%;
                  display: flex;
                  justify-content: space-between;
                  font-size: 16px;
                  line-height: 20px;
                  color: $color-main-text;
               }
            }
         }
      }
   }
   .live{
      width: 100%;
      padding: 24px;
      display: flex;
      justify-content: space-between;
      &>div{
         &:first-child{
            display: flex;
            gap:8px;
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: $color-regent-grey;
            cursor: not-allowed;
            svg{
               opacity: 0.3;
            }
         }

         &:last-child{
            width:62px;
            position: relative;
            display: flex;
            justify-content:center;
   
            &:focus-within{
              transition: all 400ms;
              span{
                svg{
                  transition: all 400ms;
                  transform: rotate(-180deg);
                }
              }
            } 
            ul{
              transition: all 400ms;
              flex-direction: column;
              top:24px;
              position:absolute;
              z-index: 2;
              background-color: $color-white;
              border-radius:12px;
              box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
   
              li{
                background:$color-white;
                font-weight: 400;
                font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
                line-height: 20px;
                color: $color-regent-grey;
                padding:8px;
   
                
                &:first-child{
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }
                &:last-child{
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }
   
                &:hover{
                  cursor: pointer;
                  color: $color-white;
                  background:$color-regent-grey;
                }
              }
            }
            span{
              background:$color-white;
              font-weight: 400;
              font-size: clamp(0.625rem, -1.25rem + 2.5vw, 1rem);
              line-height: 20px;
              align-items: center;
              color: $color-regent-grey;
              display: flex;
              gap: 5px;
              cursor: pointer;
   
                svg{
                  transition: all 400ms;
                }
            }
   
          }
      }
   }
   &>div{
      &:last-child{
         padding: 56px 24px;
         height:fit-content;
         background-color: $color-water !important;
      }
   }
}