$color-white: #fff;
$color-main-blue: #0076FF;
$color-celestial-blue :#3996D3; 
$color-water-blue : #0082CA;
$color-crystal-blue: #66ADFF;
$color-pastel-blue : #99C8FF;
$color-light-blue: #BBDAFF;
$color-secondary-light-blue: #EBF5FF;
$color-main-text: #12263E;
$color-secondary-text: #3C4F65;
$color-ebony-clay :#1A2A36;
$color-water: #EBF5FF;
$color-gainsboro :#D8DCE0;
$color-regent-grey : #8A95A3;
$color-dust : #676767;
$color-grey: #F5F6FA;
$color-light-grey: #D8DCE0;
$color-cloud: #C4C4C4;
$color-mercury : #E5E5E5;
$color-platinum : #E7E5EA;

$breakpoint-sm : 486px;
$breakpoint-md: 768px;
$breakpoint-lg: 992px;
$breakpoint-large : 1200px;
$breakpoint-xl: 1400px;
$breakpoint-xxl: 1920px;
