@import "../../../assets/scss/utils/variables";

.achievements {
  background: $color-grey;
  padding: clamp(3.5rem, 2.2rem + 6.5vw, 10rem) 0px;
  display:flex;
  justify-content:center;
  align-items:center;

  .container{
    width: 100%;
    max-width:1128px;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: column;
    gap: clamp(2.5rem, 2.2rem + 1.5vw, 4rem);

      .content-header {
        display: flex;
        flex-direction: column;
        justify-content:center;
        width: 100%;
        max-width:936px;
        row-gap: 64px;
        flex-wrap: wrap;
        text-align: center;
      } 
      .timeline-container{
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap:24px;

        svg{
          height: 40px;
          width: 40px;
          border: 1px solid $color-cloud;
          cursor: pointer;
          background: $color-grey;

          &:hover{
            background: $color-water;
            border: 1px solid $color-water-blue;
            
            path{
              stroke:$color-water-blue;
            }
          }
        }
        .timeline{
          height : 72px;
          width: 1066px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;
          &>div{
            width:100%;
            display: flex;
            
            .line-item{
              display: flex;
              justify-content:center;
              align-items:center;
              div{
                width: 268px;
                height:3px;
              }
              span{
                user-select: none;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: relative;
                right: 0;
                height: 12px;
                width: 12px;
                border-radius: 50%;

                &:hover{
                  background : $color-crystal-blue;
                  box-shadow: $color-pastel-blue 0px 2px 6px, $color-pastel-blue 0px 0px 0px 5px;
                }
                
                 p{
                  position : absolute;
                  top:16px;
                  height:30px;
                  width: 53px;
                  font-family: 'Avenir';
                  font-style: normal;
                  font-weight: 800;
                  font-size: 22px;
                  line-height: 30px;
                  text-align: center;
                 }
              }
            }
          }

        }
      }  
      .achievement{
        user-select: none;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content:center;
        width: 100%;
        max-width: 744px;
        gap:32px;

        img{
          width: 100%;
          height: auto;
        }
        p{
          font-size: clamp(1rem, 0.9671rem + 0.1646vw, 1.125rem);
          font-weight: 400;
          line-height: clamp(2.125rem, 2.2238rem + -0.4938vw, 1.75rem);
          text-align: center;
          color: $color-secondary-text;
        }
      }

  }

}

.line-grey{
  div{
    background: $color-gainsboro;
  }
  span{
    height: 12px;
    width: 12px;
    background : $color-gainsboro;
     p{
      font-weight: 800;
      color: $color-regent-grey;
     }
  }
}

.line-blue{
  div{
    background: $color-crystal-blue;
  }
  span{
    height: 12px;
    width: 12px;
    background : $color-crystal-blue;
     p{
      font-weight: 800;
      color: $color-regent-grey;
     }
  }
}

.line-active{
  div{
    transition: all 700ms;
    background: $color-crystal-blue;
  }
  span{
    transition: all 700ms;
    height: 12px;
    width: 12px;
    background : $color-crystal-blue;
    box-shadow: $color-pastel-blue 0px 2px 6px, $color-pastel-blue 0px 0px 0px 5px;
     p{
      font-weight: 900;
      color: $color-water-blue;
     }
  }
}

@media (max-width: $breakpoint-large) {
  .timeline{
    width: 688px !important;
      .line-item{
        div{
          width: 172px !important;
        }
      }
  }
  .timeline-container{
    svg{
      display: none;
    }
  }
}

@media (max-width: $breakpoint-md) {
  .timeline{
    width:100%;
    max-width: 344px !important;
  }
.content-header{
  p{
    display: none;
  }
}
.achievement{
  padding : 0px 24px;
  p{
    text-align: left !important;
  }
}
}