@import '../../../assets/scss/utils/variables.scss';

.mobile-slide {
  display: none !important;
}

@media (max-width: $breakpoint-md) {
  .mobile-slide {
    position: relative;
    display: block !important;
    width: 100%;
    height: 100%;

    iframe{
      width: 100%;
      padding: 0px;
      margin: 0px;
      height: 280px;
    }

    figure {
      position: relative;
      width: 100%;
      padding: 0px;
      margin: 0px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        display: block;
        width: 100%;
        height: 280px;
        object-fit: cover;
      }

      div {
        position: absolute;
        width: 60px;
        height: 60px;
        background: $color-water-blue;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
    }
  }
}