@import '../../../assets/scss/utils/variables.scss';

.parent{
  display: flex;
  flex-direction: column;
  align-items: center;
}
.internal {
  padding-top: 200px;

  & > .mobile-text {
    display: none !important;
  }
  & > .container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .text {
      width: 100%;
      max-width: 936px;
      display: flex;
      flex-direction: column;
      padding: 0px 24px;
      gap: 16px;
      color: $color-grey;

      h1 {
        font-size: 32px;
        font-weight: 600;
        line-height: 42px;
        letter-spacing: -1px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0px;
        margin-bottom: 64px;
      }
    }

    .content {
      width: 100%;
      background-color: $color-white;
      display: flex;
      justify-content: center;
      align-items: center;

      .memories {
        width: 100%;
        max-width: 984px;
        margin-top: -50px;
        padding: clamp(1.5rem, 0.4987rem + 2.086vw, 2.5rem);
        border: 1px solid $color-light-grey;
        height: auto;
        background: $color-white;
        display: flex;
        flex-direction: column;
        row-gap: 32px;
        margin-bottom: 258px;
        box-sizing: border-box;

        &>h1{
          font-family: EuclidCircularB;
          font-style: normal;
          font-weight: 600;
          font-size: 32px;
          line-height: 42px;
          letter-spacing: -1px;
          color: $color-main-text;
        }

        .memories-photos {
          display: flex;
          width: 100%;
          height: clamp(18.75rem, 14.9951rem + 7.8227vw, 22.5rem);
          gap: clamp(0.75rem, -0.001rem + 1.5645vw, 1.5rem);
          justify-content: center;

          & > div {
            &:first-child {
              height: 100%;
              width: auto;
              max-width: clamp(31.25rem, 24.9919rem + 13.0378vw, 37.5rem);
              img {
                height: 100%;
                object-fit: cover;
              }
            }

            &:last-child {
              width: auto;
              display: flex;
              flex-direction: column;
              height: 100%;
              row-gap: 24px;

              figure {
                height: calc(50% - 12px);
                width: auto;

                &:first-child {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;

                  div {
                    position: absolute;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    background-color: $color-water-blue;
                    cursor: pointer;

                    svg {
                      width: 10.43px;
                    }
                  }

                }

                &:last-child {
                  position: relative;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  cursor: pointer;
                  img {
                    display: block;
                    object-fit: cover;
                    filter: brightness(.6);
                  }

                  p {
                    position: absolute;
                    font-size: 20px;
                    font-weight: 600;
                    line-height: 24px;
                    color: $color-white;
                  }
                }

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }

        .infoText {
          width: 100%;

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 30px;
            letter-spacing: 0px;
            color: $color-secondary-text;

            &:first-child {
              margin-bottom: 48px;
            }
          }
        }

        .line {
          width: 100%;
          height: 1px;
          background-color: $color-platinum;
        }

        .footer {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & > div {
            &:first-child {
              display: flex;
              align-items: center;
              column-gap: 12px;

              ul {
                display: flex;
                column-gap: 12px;
              }
            }
          }

          p {
            width: 100%;
            max-width: 142px;
            text-align: left;
            font-size: 14px;
            font-weight: 400;
            line-height: 18px;
            color: $color-dust;
          }
        }
      }
    }
  }

}

@media (max-width: $breakpoint-md) {

  .internal {
    padding-top: 90px;

    & > div {
      &:first-child {
        display: none !important;
      }

      &:last-child {
        padding: 56px 24px;
        position: relative;
        margin-top: - 238px;

        h1 {
          padding-bottom: 0px;
        }
      }
    }

    .mobile-text {
      display: flex !important;
      position: relative;
      flex-direction: column;
      width: 100%;
      padding: 24px 16px;
      background: $color-grey;
      margin-bottom: 36px;
      gap: 8px;

      h3 {
        font-weight: 600;
        font-size: 18px;
        line-height: 23px;
        color: $color-main-text;
      }

      p {
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: $color-regent-grey;
      }
    }

    .container {
      .text {
        display: none !important;
      }

      .content {
        .memories {
          border: none !important;

          & > div {
            &:first-child {
              display: none !important;
            }
          }

          .footer {
            & > div {
              width: 100%;
              justify-content: center;
              align-items: center;
              gap: 12px;
              flex-direction: column;

              p {
                text-align: center;
              }
            }

            & > p {
              display: none !important;
            }
          }
        }
      }
    }

    & + footer {
      background-color: $color-white;
    }

  }


}
