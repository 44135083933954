@import '../../../assets/scss/utils/variables.scss';

.parent{
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   .container{
      display: flex;
      flex-direction: column;
      align-self: flex-end;
      justify-content: center;
      background-color: $color-white;
      padding: clamp(15.625rem, 4.9861rem + 22.1643vw, 26.25rem)
              clamp(1.875rem, -69.3929rem + 74.2857vw, 19.75rem) 
              clamp(8.75rem, -0.0114rem + 18.2529vw, 17.5rem)
              0px;
      img{
         display:block;
      }

      .content{
            background-color:$color-grey;
            width: clamp(26.5rem, -0.0346rem + 55.2803vw, 53rem);
            height:auto;
            display: flex;
            gap: clamp(3.0625rem, -0.004rem + 6.3885vw, 6.125rem);
            justify-content:space-between;
            padding: clamp(2.1875rem, -0.0029rem + 4.5632vw, 4.375rem)  clamp(2.5rem, -0.0033rem + 5.2151vw, 5rem)  clamp(2.5rem, -0.0033rem + 5.2151vw, 5rem) 0px;
            box-sizing: border-box;
    
            &>div{
               &:first-child{
                  width: clamp(3.875rem, -0.0051rem + 8.0834vw, 7.75rem);
                  height: clamp(9.25rem, -0.0121rem + 19.296vw, 18.5rem);
                  background: $color-crystal-blue;
                  position: relative;
    
                  &>div{
                   position:absolute;
                   width: clamp(20rem, -0.0261rem + 41.721vw, 40rem);
                   right:24px;
                   bottom:24px;
                  }
                  &>img{
                     position:absolute;
    
                     &:first-child{
                        width: clamp(20rem, -0.0261rem + 41.721vw, 40rem);
                        right:24px;
                        bottom:24px;
                     }
                     &:last-child{
                        width: auto;
                        height: clamp(10rem, 2.2517rem + 15.894vw, 17.5rem);
                        object-fit: cover;
                        right: 100%;
                        bottom: calc( 64px - 100%);
                     }
                  }
               }
               &:last-child{
                   position: relative;
                   width: 100%;
                   height: 100%;
                   display: flex;
                   flex-direction:column;
                   gap: clamp(1rem, -0.0013rem + 2.086vw, 2rem);
                   div{
                    position: absolute;
                    top : clamp(-8.125rem, -1.8709rem + -6.5189vw, -5rem);
                    p{
                     font-style: normal;
                     font-weight: 400;
                     font-size: clamp(0.75rem, 0.4997rem + 0.5215vw, 1rem);
                     line-height: 20px;
                     color: $color-regent-grey ;
                    }
                    h2{
                     font-style: normal;
                     font-weight: 400;
                     font-size: clamp(1.625rem, 0.375rem + 2.6042vw, 3.5rem);
                     line-height: clamp(2.5rem, 0.4974rem + 4.1721vw, 4.5rem);
                     letter-spacing: -0.01em;
                     color: $color-secondary-text;
                     b{
                        font-weight: 600;
                     }
                    }
                   }
                   &>p{
                      font-style: normal;
                      font-weight: 400;
                      font-size: clamp(0.8125rem, 0.4996rem + 0.6519vw, 1.125rem);
                      line-height: clamp(1.25rem, 0.6242rem + 1.3038vw, 1.875rem);
                      color: $color-secondary-text;
                      width: 100%;
                      max-width: 550px;
                      padding-right:20px;
                     }
                     &>a{
                      background-color: $color-white;
                      width: 161px;
                      color:$color-secondary-text;
                      position: relative;
                      border: 1px solid $color-pastel-blue;
                      &:after {
                         content: '';
                         width: 1px;
                         height: 59px;
                         background: $color-pastel-blue;
                         display: block;
                         position: absolute;
                         transform: rotate(17deg);
                         top: -1px;
                         right: 8px;
                       }
                      svg{
                         path{
                            stroke:$color-secondary-text;
                         }
                      }
                     }
               }
    
               }
    
            }
    
    }
}

