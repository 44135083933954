.mySwiper-news{
  width: 100%;
  height: 100%;
}

@media (max-width:768px){
  .mySwiper-news>div>div{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    max-width: clamp(16.25rem, 12.0833rem + 13.8889vw, 18.75rem) !important;
  }
}




