@import "../../../assets/scss/utils/variables";

.button {
  background: $color-water-blue;
  outline: none;
  border: none;
  padding: 16px;
  color: $color-white;
  font-size: 16px;
  line-height: 20px;
  cursor: pointer;
  text-align: center;
  transition: all 400ms;
  
  &.left{
    clip-path: polygon(0 0, 100% 0, 100% 100%, 10% 100%);
  }
  &.right {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0 100%);
  }

  &:hover{
    opacity: 0.6;
    span {
      svg {
        transform: translateX(10px);
      }
    }
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    width: 100%;

    svg {
      transition: all 400ms;
    }
  }
}
