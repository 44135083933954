@import "../../../assets/scss/utils/variables.scss";

.social-icon {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid $color-gainsboro;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;
  transition: all 400ms;

  &:hover {
    background-color: $color-main-blue;
    border-color: $color-main-blue;

    svg {
      fill: $color-white;

      path {
        fill: $color-white;
      }
    }
  }
}