@import "../../../assets/scss/utils/variables";

.news {
  background: $color-white;

  .content {
    display: flex;
    flex-direction: column;
    row-gap: clamp(2rem, 1.4733rem + 2.6337vw, 4rem);
    padding: clamp(3.5rem, 2.4465rem + 5.2675vw, 7.5rem) 0 clamp(3.5rem, 1.7881rem + 8.5597vw, 10rem) 0;

    .header {
      display: flex;
      justify-content: space-between;

      .read-more {
        display: flex;
        align-items: center;
        color: $color-main-text;
        column-gap: 14px;
        font-size: 20px;
        line-height: 24px;

        svg {
          transition: all 400ms;
          path {
            stroke: $color-main-blue;
          }
        }

        &:hover {
          svg {
            transform: translateX(10px);
          }
        }
      }
    }

    .body {
      display: flex;
      gap: clamp(1rem, 0.8683rem + 0.6584vw, 1.5rem);
    }
  }
}
.read-more-bottom{
  width: 248px;
  height: 56px;
  border: 1px solid $color-cloud;
  background-color: $color-white;
  display: flex;
  justify-content:center;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: $color-secondary-text;
  margin-left: 0px;
}
@media (max-width: $breakpoint-md) {
  .header{
    justify-content: center !important;
    .read-more{
      display: none !important;
    }
  }
}