@import "../../assets/scss/utils/variables.scss";
.contact{

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: clamp(0rem, -1.1rem + 5.5vw, 5.5rem);
    .text{
       padding-top: 200px;
    }
    .address{
      margin: 120px 0px;
      display: flex;
      gap: 24px;
      justify-content:space-between;
      align-items: center;
      padding: 24px;
      &>div{
        &:first-child{
          display: flex;
          flex-direction: column;
          gap: clamp(2rem, -1.3661rem + 5.9843vw, 4.375rem);
          .address-item{
            width: 100%;
            max-width:360px;
            display: flex;
            align-items: center;
            gap: clamp(0.75rem, -0.8445rem + 2.8346vw, 1.875rem);

            &>div{
              &:first-child{
                width: clamp(2.5rem, 0.7283rem + 3.1496vw, 3.75rem);
                height: clamp(2.5rem, 0.7283rem + 3.1496vw, 3.75rem);
              }
              h6{
                font-size: 24px;
                font-weight: 600;
                line-height: 30px;
                color:$color-main-text;
                margin-bottom:22px;
              }
              p{
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                max-width: 270px;
                font-size: clamp(1rem, 0.8228rem + 0.315vw, 1.125rem);
                font-weight: 400;
                line-height: 30px;
                color:$color-main-text;
              }
            }
          }
      }
      }

      figure{
        width: calc(100% - 360px);
        img{
          display:block;
          width: 100%;
        }
      }
    }
    .appeal{
      width:100%;
      max-width: 1128px;
      background: #EBF5FF99;
      border: 1px solid $color-gainsboro;
      padding: 56px clamp(1.5rem, 0.3148rem + 5.9259vw, 6rem);
      display: flex;
      flex-direction: column;
      gap: clamp(1.375rem, 1.25rem + 0.625vw, 2rem);
      &>h3{
        font-weight: 600;
        font-size: clamp(1.75rem, 1.6842rem + 0.3292vw, 2rem);
        line-height: 30px;
        letter-spacing: -0.3px;
      }
      &>div{
        display:flex;
        width: 100%;
        gap:32px;
        &>div{
          width: 33% !important;
        }
        input{
          width: 100% !important;
        }
        & > select {
          width: 33%;
          padding: 18px 24px;
          border: 1px solid $color-gainsboro;
          background: $color-white;
          font-weight: 400;
          height: 56px;
          font-size: 16px;
          line-height: 20px;
          color: $color-regent-grey;
          -webkit-appearance: none;
          appearance: none;
          -moz-appearance: none;
          background-image: url("https://www.svgrepo.com/show/31769/down-arrow.svg");
          background-repeat: no-repeat;
          background-size: 8px 8px;
          background-position: calc(100% - 24px);
       }
      }
      &>button{
        width: 100%;
        max-width: 296px;
      }
    }
}
iframe{
  border: 0;
  width: 100%;
  height: 450px;
  margin-top: 3rem;
}
@media (max-width:$breakpoint-lg) {
  .address-item{
    &>div{
      h6{
        display: none;
      }
    }
  }
}
@media (max-width:$breakpoint-md) {
  .text{
    padding-top: 130px !important;
    height:200px;
    &>div{
      display: none;
    }
    h1{
      color: $color-ebony-clay;
    }
  }
  .reverse{
    display: flex;
    flex-direction:column-reverse;
  }
  .appeal{
    display: flex;
    justify-content: center;
    align-items: center;
    &>div{
        flex-direction:column;
        width: 100% !important;
      &>div{
        width: 100% !important;
        min-width:100% !important;
      }
      &>select{
        width: 100% !important;
        min-width:100% !important;
      }
    }
    textarea{
      min-width: 100%;
    }
    button{
      min-width:100%;
    }
  }
  .address{
    width:100%;
    padding:0px !important;
    flex-direction:column;
    margin:0px 0px 30px 0px !important;
    &>div{
      &:first-child{
        display: none !important;
      }
      &:nth-child(2){
        padding: 24px;
      }
    }
  }
  figure{
    width: 100% !important;
    height:auto;
    padding:0px;
    margin:0px;
    img{
      width: 100%;
    }
  }
}
