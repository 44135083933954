@import '../../assets/scss/utils/variables.scss';

.gallery {
   padding-top: 200px;
   .container{
    padding: clamp(1.5rem, -1.0033rem + 5.2151vw, 4rem) 24px clamp(4rem, -7.8905rem + 24.7718vw, 15.875rem) 24px;
    display: flex;
    justify-content:center;
    width: 100%;
    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      row-gap: 20px;
      align-items: center;
      margin: 0 3rem;
    }
   }
}



.image-col{
   // margin-top: 50rem;
   // width: 18rem;
   width: clamp(17rem, 11rem + 8.5vw, 26rem);
   display: flex;
   flex-direction: column;
   gap:24px;
   img{
      width: 100%;
      object-fit: cover;
      display:block;
      cursor: pointer;
      // &:first-child{
      //    height: clamp(20rem, 14.3374rem + 28.3128vw, 41.5rem);
      // }
      // &:last-child{
      //    height: clamp(20rem, 18.0247rem + 9.8765vw, 27.5rem);
      // }
   }
   &:nth-child(3n - 1){
      display: flex;
      flex-direction : column-reverse;
   }
}
.video{
   width: 100%;
   justify-content: center;
   align-items: center;
   display: flex;
   gap:24px;
   flex-wrap: wrap;
   iframe{
      border:none;
      &:first-child{
        width: clamp(17rem, -12.1606rem + 54.8905vw, 40.5rem);
        height: clamp(20rem, 15.0365rem + 9.3431vw, 24rem);
      }
      &:last-child{
         width : clamp(17rem, 2.7299rem + 26.8613vw, 28.5rem);
         height: clamp(20rem, 15.0365rem + 9.3431vw, 24rem);
      }
   }
   &:nth-child(2n){
      flex-direction: row-reverse;
   }
}

@media (max-width: $breakpoint-md) {
   .gallery{
      padding-top: 120px;
      &>div>h1{
        color: $color-ebony-clay;
      }
      &>div{
         &:last-child{
            padding: 56px 24px;
            h1{
               padding:0px;
            }
         }
      }
      &+footer{
        background-color: $color-white;
      }
   }
 }
