@import '../../assets/scss/utils/variables.scss';
.container {
   padding-top: 200px;
   width: 100%;
   .content {
     padding : clamp(2rem, 1.2099rem + 3.9506vw, 5rem) 36px clamp(4rem, -2.1564rem + 30.7819vw, 27.375rem) 36px;
     display: flex;
     flex-wrap: wrap;
     gap:24px;
     justify-content: center;
   }
}
.parent{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

 @media (max-width: $breakpoint-md) {
   .container{
      padding-top: 120px;
      &>h1{
        color: $color-ebony-clay;
      }
      &>div{
        &:nth-child(2){
          display:none;
        }
        &:last-child{
          margin-top: 24px;
          padding: 56px 24px;
          h1{
            padding:0px;
          }
        }
      }

   }
   .parent{
    &+footer{
      background-color: $color-white;
    }
   }
 }
