@import "../../../assets/scss/utils/variables";

.video{
   position: relative;
   cursor: pointer;
   display: flex;
   justify-content: center;
   align-items: center;
   z-index: 0;
   width: clamp(20rem, -0.0261rem + 41.721vw, 40rem);
   height: 100%;
   aspect-ratio: 4/3;
   iframe{
      width:100%;
      height:100%;
      object-fit: cover;
   }
   img{
      width:100%;
      position: absolute;
      height:100%;
      object-fit: cover;
   }
   span{
      position: absolute;
      width: 80px;
      height: 80px;
      background-color: $color-white;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
   }
}
