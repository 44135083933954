@import '../../../../assets/scss/utils/variables.scss';
.button {
   background-color: $color-white;
   border: 1px solid #C4C4C4;
   position: relative;
   display: flex;
   flex-direction: row;
   align-items: center;
   gap:7px;
   padding: 16px 23px;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 20px;
   color: #12263E;
   height: 52px;
   width: 173px;
   transition: all 400ms;

   svg{
     transition: all 400ms;
      path{
         stroke:#0082CA;
      }
   }
   &:hover{
      transition: all 400ms;
      opacity: 0.6;
        svg {
          transition: all 400ms;
          transform: translateX(10px);
        }
    }
  
}

.card{
   width: clamp(17rem, 2.9095rem + 70.4527vw, 70.5rem);
   border: 1px solid #C4C4C4;
   height: auto;
   padding: clamp(1.5rem, 1.2366rem + 1.3169vw, 2.5rem) clamp(1rem, 0.6049rem + 1.9753vw, 2.5rem);
   display: flex;
   gap:32px;

      img{
         display: block;
         max-width: 264px;
         min-height : 100% !important;
         object-fit: cover;
      }
      &>div{
         display: flex;
         flex-direction: column;
         justify-content: space-between;
         width:100%;

         &>p{
            &:first-child{
               display:none;
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #8A95A3;
            }
            &:nth-of-type(2){
               font-weight: 400;
               font-size: clamp(1rem, 0.9671rem + 0.1646vw, 1.125rem);
               line-height: clamp(1.75rem, 1.7171rem + 0.1646vw, 1.875rem);
               color: #3C4F65;
            }
         }
         h2{
            font-style: normal;
            font-weight: 600;
            font-size: clamp(1.25rem, 1.0525rem + 0.9877vw, 2rem);
            line-height: clamp(1.875rem, 1.6775rem + 0.9877vw, 2.625rem);
            letter-spacing: -1px;
            color: #12263E;
         }
         &>div{
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            p{
               font-weight: 400;
               font-size: 16px;
               line-height: 20px;
               color: #8A95A3;
            }
         }
      }

}

@media (max-width: $breakpoint-md) {
   .card{
      display: flex;
      justify-content: center;
      align-items: center;
      img{
         display: none !important;
      }
      &>div{
         gap:16px;
         width:100% !important;
         &>p{
            &:first-child{
               display: block;
            }
         }
         &>div{
            p{
               display: none;
            }
         }
      }
   }
}