@import '../../assets/scss/utils/variables.scss';
.news {
  padding-top: 200px;

  .content {
    width: 100%;
    padding: 120px 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 18px;
  }
}
@media (max-width: $breakpoint-md) {
  .news{
    h1{
      color: $color-ebony-clay;
      margin-top:-70px;
      padding-bottom:0px;
    }
  }
} 