@import '../../../assets/scss/utils/variables.scss';
.container{
   width: calc(100% - 32px );
   max-width: clamp(53.25rem, 0.4221rem + 55.0649vw, 66.5rem);
   flex-direction: row;
   aspect-ratio: 2/1;
   background-color: $color-white;
   position: fixed;
   top:160px;
   z-index: 20;
   padding: 0;
   box-sizing: border-box;
   img{
      width: 50%;
      height: 100%;
      object-fit: cover;
      margin:0px;
      display:block;
      float: left;
   }
   &>div{
      display: flex;
      background-color: $color-white;
      width: 50%;
      height: 100%;
      padding:
       clamp(0.75rem, 0.5827rem + 0.9562vw, 1.5rem) clamp(0.75rem, 0.5827rem + 0.9562vw, 1.5rem)
       clamp(1.25rem, 0.9711rem + 1.5936vw, 2.5rem) clamp(1.25rem, 0.9711rem + 1.5936vw, 2.5rem);
      box-sizing: border-box;
      flex-direction: column;
      justify-content:space-between;
      align-items:space-between;
      gap:13px;
      &>div{
         height: 30px;
         &:first-child{
            width:100%;
            display:flex;
            justify-self:flex-end;
            position: relative;
            svg{
               position:absolute;
               top:0;
               right:0;
               width:  clamp(1.625rem, 0.1299rem + 1.5584vw, 2rem);
               cursor: pointer;
            }
         }
         &:last-child{
            width: 100%;
            display: flex;
            justify-content:space-between;
            justify-self:flex-end;
            padding-right: clamp(0.5rem, 0.3884rem + 0.6375vw, 1rem);
            gap: clamp(0.5rem, 0.3884rem + 0.6375vw, 1rem);
            button{
              width:100%;
              height: clamp(2.5rem, 2.2769rem + 1.2749vw, 3.5rem);
              background: $color-white;
              display: flex;
              justify-content:center;
              align-items:center;
              font-style: normal;
              font-weight: 400;
              gap: clamp(0.40625rem, 0.3156rem + 0.5179vw, 0.8125rem);
              font-size: clamp(0.75rem, 0.6524rem + 0.5578vw, 1.1875rem);
              line-height: clamp(1.125rem, 1.0413rem + 0.4781vw, 1.5rem);
              cursor: pointer;
              &:first-child{
               border: 1.33px solid $color-gainsboro;
               color: $color-secondary-text;
               svg{
                  transition: all 400ms;
                  width: clamp(0.6875rem, 0.5341rem + 0.8765vw, 1.375rem);
                  path{
                     stroke: $color-secondary-text;
                  }
               }

              }
              &:last-child{
               border: 1.33px solid $color-water-blue;
               color: $color-water-blue;
               svg{
                  width: clamp(0.6875rem, 0.5341rem + 0.8765vw, 1.375rem);
                  transition: all 400ms;
                  path{
                     stroke: $color-water-blue;
                  }
               }

              }
            }
         }
      }
      h2{
         font-size: clamp(1rem, 0.7769rem + 1.2749vw, 2rem);
         font-weight: 600;
         line-height: clamp(1.3125rem, 1.0197rem + 1.6733vw, 2.625rem);
         letter-spacing: -1px;
         text-align: left;
         color:$color-main-text;
      }
      p{
         margin-top: clamp(0.75rem, 0.5827rem + 0.9562vw, 1.5rem);
         font-size: clamp(0.6875rem, 0.5341rem + 0.8765vw, 1.375rem);
         font-weight: 400;
         line-height:  clamp(2rem, 0.5049rem + 1.5584vw, 2.375rem);
         word-wrap: break-word;
         letter-spacing: 0px;
         text-align: left;
         color:$color-secondary-text;
         padding-right: clamp(0.5rem, 0.3884rem + 0.6375vw, 1rem);
         box-sizing: border-box;
         max-height:200px;
         overflow-y: auto;
      }
   }
}

.prev{
   &:hover{
      svg{
         transform: translateX(-10px);
      }
   }
}
.next{
   &:hover{
      svg{
         transform: translateX(10px);
      }
   }
}
.module{
   display: flex;
   position: fixed;
   top:160px;
   gap: clamp(0.75rem, 0.5827rem + 0.9562vw, 1.5rem);
   &>div{
      box-sizing: border-box;
      padding: clamp(0.75rem, 0.5827rem + 0.9562vw, 1.5rem);
      &>div{
         height: 30px;
         &:first-child{
            width:100%;
            display:flex;
            justify-self:flex-end;
            position: relative;
            svg{
               position:absolute;
               top:0;
               right:0;
               width:  clamp(1.625rem, 0.1299rem + 1.5584vw, 2rem);
               cursor: pointer;
            }
         }
      }
      h2{

      }
      p{
         overflow-x: auto;
         word-wrap: break-word;
         max-height: clamp(9.375rem, 7.2834rem + 11.9522vw, 18.75rem);
         height: 300px;
         direction: ltr;
         scrollbar-color: $color-light-grey  $color-white;
         scrollbar-width: thin ;
          &::-webkit-scrollbar {
            width: 4px;
          }
          &::-webkit-scrollbar-track {
            background: $color-white;
          }
          &::-webkit-scrollbar-thumb {
            background: $color-light-grey;
            border-radius: 4px;
          }
          &::-webkit-scrollbar-thumb:hover {
            background: rgb(164, 163, 163);
          }
      }
   }

}
.module3{
   height:auto !important;
   aspect-ratio: 0 !important;
   &>div{
      display: flex;
      padding:16px !important;
      width:100%;
      height: max-content;
      gap:45px;
      background-color: $color-white;
      &>div{
         height: 30px;
         &:first-child{
            width:100%;
            display:flex;
            justify-self:flex-end;
            position: relative;
            svg{
               position:absolute;
               top:0;
               right:0;
               width:  clamp(1.625rem, 0.1299rem + 1.5584vw, 2rem);
               cursor: pointer;
            }
         }
      }
      p{
         height:100%;
         min-height: 130px;
         margin-top: -20px !important;
      }
   }
}
@media (max-width: $breakpoint-md){
   .container{
      flex-direction: column-reverse !important;
      img{
         display:none !important;
      }
      &>div{
         width: 100% !important;
      }
   }
   .module3{
      flex-direction:column !important;
      &>div{
         display: flex;
         flex-direction:column !important;
         gap:32px;
      }
      p{
         overflow-y: scroll;
      }

   }
}