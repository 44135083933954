@import '../../assets/scss/utils/variables.scss';

.container{
   padding-top: 200px;

   &>h1{
     margin-bottom:40px;
   }

   .content{
      padding:
      clamp(2rem, 0rem + 4.1667vw, 5rem) 
      clamp(1.5rem, -14rem + 32.2917vw, 24.75rem) 
      clamp(4rem, -15.5833rem + 40.7986vw, 33.375rem)
      clamp(1.5rem, -14rem + 32.2917vw, 24.75rem);
      width: 100%;
      background-color:$color-white;
      box-sizing: border-box;
      display: flex;
      flex-wrap: wrap;
      gap:24px;
      justify-content:center;
   }
}
@media (max-width: $breakpoint-md) {
   .container{
     h1{
       color: $color-ebony-clay;
       margin-top:-70px;
       padding-bottom:0px;
     }
   }
 } 