@import "../../assets/scss/utils/variables";
.achievements {
   padding-top: 200px;
   display: flex;
   justify-content:center;
   align-items:center;
   width: 100%;

    &>div{
      width: 100%;
      &>section{
        width: 100%;
        background-color: $color-white;
      }
    }
}
@media (max-width: $breakpoint-md) {
.achievements{
 .content{
  width: 100%;
   &>div{
     width: 100%;
     &:nth-of-type(1){
       display: none;
     }
   }
   &>h1{
    width: 100%;
    color: $color-ebony-clay;
    margin-top:-70px;
    padding-bottom:30px;
  }
 }
}
} 