.swiper-zoom{
   width: calc(100% - 32px);
   height: 100%;
   max-height: clamp(32.3125rem, 1.6623rem + 31.9481vw, 40rem);
   max-width: clamp(53.125rem, -0.2013rem + 55.5844vw, 66.5rem);
   position: fixed;
   z-index:30;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.swiper-zoom>span{
   cursor: pointer;
   top:-36px;
   right:0px;
   position:absolute;
   z-index: 40;
   width:89px;
   height:36px;
   padding:8px 12px;
   display:flex;
   gap:4px;
   font-style: normal;
   font-weight: 500;
   font-size: 16px;
   line-height: 20px;
   color: #0082CA;
   align-items: center;
   background-color:white;
}
.swiper-zoom>span svg path{
   stroke: #0082CA;
}
.swiper-zoom .controls{
   display: flex;
   justify-content: space-between;
   margin:0px auto;
   justify-self: center;
   align-self: center;
   padding: 18px 24px;
   position: absolute;
   width: 264px;
   height: 60px;
   top: 16px;
   background: rgba(34, 30, 28, 0.7);
   backdrop-filter: blur(6px);
   z-index:2;
   border-radius: 8px;
}
.swiper-zoom .controls svg{
   cursor: pointer;
}
.zoom .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.zoom .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.zoom .swiper-slide iframe {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.zoom .swiper-slide div{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.zoom .swiper-slide div iframe{
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-zoom .bottom-slide{
   position: absolute;
   bottom:0px;
   background: transparent;
   display: flex;
   justify-content: flex-start;
   align-items: flex-start;
   margin : 0px !important;
   padding: 0px 0px 24px 16px !important;
   z-index:90;
   width: 100% !important;
   height: 100%;
   max-height: 128px;
}
.swiper-zoom .bottom-slide>div{
   display: flex;
   justify-content: flex-start;
   width: 100% !important;
   margin : 0px !important;
   padding: 0px !important;
   height: 100%;
}
.swiper-zoom .bottom-slide>div>div{
   cursor: pointer;
}

.overlay{
      position: fixed; /* Sit on top of the page content */
      /* display: none; Hidden by default */
      width: 100%; /* Full width (cover the whole page) */
      height: 100%; /* Full height (cover the whole page) */
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0,0,0,0.5); /* Black background with opacity */
      z-index: 20; /* Specify a stack order in case you're using a different order for other elements */
      /* cursor: pointer;  */
   }