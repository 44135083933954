.gallery-table {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: clamp(1.5rem, 0.4987rem + 2.086vw, 2.5rem);
}

.gallery-table .react-tabs__tab-list {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gallery-table .react-tabs__tab {
  cursor: pointer;
  width: 100%;
  max-width: 101px;
  background: #fff;
  padding: 24px;
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.01em;
  color: #12263E;
}

.gallery-table .react-tabs__tab--selected {
  box-shadow: 0px -2px 0px 0px #0082CA inset;
  color: #0082CA;
}

.gallery-table .react-tabs__tab--disabled {
  cursor: default;
}

.gallery-table .react-tabs__tab:focus {
  outline: none;
}

.gallery-table .react-tabs__tab-panel {
  display: none;
  width: 100%;
}

.gallery-table .react-tabs__tab-panel:last-child {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
}

.gallery-table .react-tabs__tab-panel--selected {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  gap: 24px;
  width: 100%;
  flex-wrap: wrap;
}